

$border-shortcuts: ("border": "b") !default;
$border-directions: ("top": "t", "right": "r", "bottom": "b", "left": "l", "":"a") !default;
$border-values: ("0": 0, "1": 1px, "2": 2px, "3": 3px, "4": 4px, "5": 5px) !default;
$border-type: (solid: "so", dotted:"do",dashed:"da") !default;
$border-colors: (black:'black', $grey:'grey', white:'white', $primary:'primary', $secondary:'secondary',transparent: 'transparent') !default;

//ex:btso-2-black

@each $property, $shortcut in $border-shortcuts {
    @each $name, $value in $border-values {
        @each $direction, $suffix in $border-directions {
            @each $color, $suffixcolor in $border-colors {
                @each $type, $suffixtype in $border-type {

                .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor} {
                    #{$property}-#{$direction}: $value $type $color!important;
                }

                @include mobile {
                    .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor}-mobile {
                    #{$property}-#{$direction}: $value $type  $color!important;
                    border-color: $color!important;

                    }
                }
                @include tablet {
                    .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor}-tablet {
                        #{$property}-#{$direction}: $value $type  $color!important;
                        border-color: $color!important;

                        }
                    }
                @include touch {
                    .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor}-touch {
                        #{$property}-#{$direction}: $value $type  $color!important;
                        border-color: $color!important;

                        }
                    }
                @include desktop {
                    .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor}-desktop {
                        #{$property}-#{$direction}: $value $type  $color!important;
                        border-color: $color!important;

                        }
                    }
                @include widescreen {
                    .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor}-widescreen {
                        #{$property}-#{$direction}: $value $type  $color!important;
                        border-color: $color!important;

                        }
                }
                @include fullhd {
                    .#{$shortcut}#{$suffix}#{$suffixtype}-#{$name}-#{$suffixcolor}-fullhd {
                        #{$property}-#{$direction}: $value $type  $color!important;
                        border-color: $color!important;

                        }
                    }
                }
            }
        }
    }

}

.no-border{
    border: none !important;
}

@font-face {
    font-family: 'aileron';
    font-style: normal;
    src: url(../../fonts/aileron-regular-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-regular-webfont.woff) format('woff');
    font-weight: 400;
    font-style: swap;
}


@font-face {
    font-family: 'aileron';
    font-style: normal;
    src: url(../../fonts/aileron-light-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-light-webfont.woff) format('woff');
    font-weight: 300;
    font-style: swap;
}

@font-face {
    font-family: 'aileron';
    font-style: normal;
    src: url(../../fonts/aileron-semibold-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-semibold-webfont.woff) format('woff');
    font-weight: 600;
    font-style: swap;
}

@font-face {
    font-family: 'aileron';
    font-style: normal;
    src: url(../../fonts/aileron-bold-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: swap;
}

@font-face {
    font-family: 'aileron';
    font-style: italic;
    src: url(../../fonts/aileron-italic-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-italic-webfont.woff) format('woff');
    font-weight: 400;
    font-style: swap;
}


@font-face {
    font-family: 'aileron';
    font-style: italic;
    src: url(../../fonts/aileron-lightitalic-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-lightitalic-webfont.woff) format('woff');
    font-weight: 300;
    font-style: swap;
}

@font-face {
    font-family: 'aileron';
    font-style: italic;
    src: url(../../fonts/aileron-semibolditalic-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-semibolditalic-webfont.woff) format('woff');
    font-weight: 600;
    font-style: swap;
}

@font-face {
    font-family: 'aileron';
    font-style: italic;
    src: url(../../fonts/aileron-bolditalic-webfont.woff2) format('woff2'),
         url(../../fonts/aileron-bolditalic-webfont.woff) format('woff');
    font-weight: 700;
    font-style: swap;
}

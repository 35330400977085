footer {
    > div:nth-child(1) {
        img {
            width: 13rem;
            filter: brightness(0) invert(100%);
        }
        @include mobile {
            a {
                display: flex;
                margin-top: var(--space-xs);
            }
            p {
                display: block !important;
                >span>span {
                    display: block;
                }
            }
        }
    }
    > div:nth-child(2) {
        ul {
            gap: var(--space-m);
        }
    }
}
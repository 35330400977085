.scrolltop {
    .js & {
        opacity: 0;
    }
    position: fixed;
    bottom: var(--space-m);
    right: var(--space-m);
    transition: all .5s ease-in-out;
    width: var(--space-xl);
    height: var(--space-xl);
    &.visible {
        opacity: 1;
    }
    circle {
        fill: $black;
    }
    path {
        fill: $white;
    }
    svg {
        width: var(--space-xl);
        height: var(--space-xl);
        // @include tablet {
        //     width: 40px;
        //     height: 40px;
        // }
        // @include desktop {
        //     width: 80px;
        //     height: 80px;
        // }
    }
    // &:hover {
    //    transform: rotate(1turn);
    // }
}

.site-logo {
    width: 22.5rem ;
    &::after{
        border-bottom: 1px solid transparent;
        -moz-border-image: -moz-linear-gradient(left, rgb(255, 255, 255) 0%,  rgba(1, 63, 76, 0) 100%);
        -webkit-border-image: -webkit-linear-gradient(left, rgb(255, 255, 255) 0%, rgba(1, 63, 76, 0) 100%);
        border-image: linear-gradient(to right, rgb(255, 255, 255)  0%, rgba(1, 63, 76, 0) 100%);
        border-image-slice: 1;
        position: absolute;
        content: '';
        width: 50%;
        height: 1px;
        top: 143px;
    }

    &.no-border{
        &::after{
            display: none;
        }
    }
    &.verd{
        &::after{
        border-bottom: 1px solid transparent;
        -moz-border-image: -moz-linear-gradient(left, rgb(1, 63, 76) 0%,  rgba(1, 63, 76, 0) 100%);
        -webkit-border-image: -webkit-linear-gradient(left, rgb(1, 63, 76) 0%, rgba(1, 63, 76, 0) 100%);
        border-image: linear-gradient(to right, rgb(1, 63, 76)  0%, rgba(1, 63, 76, 0) 100%);
        border-image-slice: 1;
        position: absolute;
        content: '';
        width: 65%;
        height: 1px;
        top: 143px;
        }

    }

    @include mobile {
        width: 16rem;
        &::after{
            width: 70%;
            top: 105px;
        }
        &.verd{
            &::after{
                top: 105px;
            }
        }

    }

}

// mòbil
.hamburger {
    cursor: default;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    @include tablet {
        top: 3.5rem;
        right: 11rem;
    }
    @include fullhd {
        display: none;
    }
}
.navigation {
    @include tablet {
        //margin-top: var(--space-l);
    }
    @include fullhd {
        margin-top: 0;
    }
}
#menu {
    > div {
        @include tablet {
            display: flex;
            gap: var(--space-l);
            align-items: flex-start;
        }
        @include fullhd {
            display: block;
        }
        > div:first-child {
            @include desktop {
                flex-basis: 25%;
            }
        }
    }
    .wrapper-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--space-m);
        flex-wrap: wrap;
        @include widescreen {
            flex-wrap: nowrap;
        }
    }
    @include fullhd {
        display: block;
        .wrapper-desktop {
            display: flex;
            gap: var(--space-m);
            justify-content: flex-end;
            align-items: center;
            margin-top: var(--space-s);
        }
    }
}

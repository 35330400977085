.search-form {
    position: relative;

    @include mobile {
        margin-top: var(--space-m);
        width: auto;
        margin-left: auto;
        margin-right: auto;
        input{
            font-size: 0.875rem;
            input{
                &::placeholder{
                    font-size:0.75rem;
                }
            }
        }
    }

    @include tablet {
        margin-top: var(--space-m);
    }

    @include fullhd {
        margin-top: 0;
    }

    input {
        width: calc(100% - 1rem);
        border: 1px solid $primary;
        background: transparent;
        border-radius: 5px;
        height: 3rem;
        color:$primary;
        &:focus {
            border: 1px solid $primary;
        }
    }

    button {
        position: absolute;
        top: 0;
        right: 9px;
        background-color: $primary;
        border-radius: 5px;
        padding: 12px 12px 11px 12px;
    }

    &.home {
        position: relative;
        background: none;
        @include mobile {
            margin-top: 0;
            width: auto;
            margin-left: auto;
            margin-right: auto;
            input{
                &::placeholder{
                    font-size:0.75rem;
                }
            }
        }

        @include tablet {
            margin-top: var(--space-m);
        }

        @include fullhd {
            margin-top: 0;
        }

        input {
            width: calc(100% - 1rem);
            border: 1px solid $white;
            background: transparent;
            border-radius: 5px;
            height: 3rem;
            color:#fff;
            &::placeholder{
                color: #fff;
            }
            &:focus {
                border: 1px solid $white;
                outline: none;
            }
        }


        .result{
            max-height: 40vh;
            overflow-y: scroll;

            //display: none;
            position: absolute;
            top: 2.3rem;
            right: 13px;
            z-index: 1;
            width: 98%;
            font-size: 1rem;
            > a{
                position: relative;
                background-color: $tertiary;
                border-radius: 5px;
                padding: 12px;
                border-color: $tertiary;
                padding: 13px 12px 12px 12px;
                margin-bottom: 5px;
                display: block;
                width: 100%;
                color:#ffffff;
                    &:last-child{
                        border-color: #fff;
                        background-color: #fff;
                        color: $primary;
                    }
                }
            svg{
                position: absolute;
                right: 15px;
                top: 15px;
            }

            @include mobile(){
                right: 12px;
                z-index: 1;
                width: 97%;
                font-size:0.75rem;
            }
        }
    }
}

// ample de punta a punta
.full-bleed {
    body:not(.has-side-col) & {
        width: 100vw;
        margin-left: calc(50% - 50vw);
    }
    padding-top: var(--space-l);
    padding-bottom: var(--space-l);
    &--img {
        padding-top: 0;
        padding-bottom: 0;
        body:not(.has-side-col) & {
            img {
                width: 100vw;
                margin-left: calc(50% - 50vw);
                max-width: none;
            }
        }
    }
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--space-l);
    padding-right: var(--space-l);
    &--narrow {
        max-width: 80rem;
    }
    &--narrower {
        max-width: 40rem;
    }
    @include widescreen(){
        max-width: 80.5rem;
    }
    @include fullhd(){
        max-width: 110.5rem;
    }
}

.lang-menu{
    font-size: var(--step-0);
    a{
        opacity: 0.5;
        &.selected{
            opacity: 1;
        }
    }
    &--home{
        a{
            color: #fff;
        }
    }

    @include mobile(){
        margin: 0 !important;
        padding: 0;
        position: absolute;
        top: 22px;
        right: 5px;
        padding: 0;
        font-size: var(--step--2);
        li{
            border: none !important;
            a{
                padding-right:5px !important;
                padding-left:5px !important;
            }
        }

    }
}
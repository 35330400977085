[class*="cols-list-"] {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    li {
        flex: none;
        padding: 0.75rem;
        width: 100%;
        &::before {
            display: none !important;
        }
    }
    ::marker {
        content: "";
        font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
    }
}
// 2 columnes
.cols-list-2 li {
    @include tablet {
        width: 50%;
    }
}
// 3 columnes
.cols-list-3 li {
    @include tablet {
        width: 33.333%;
    }
}
// 4 columnes
.cols-list-4 li {
    @include tablet {
        width: 33.333%;
    }
    @include desktop {
        width: 25%;
    }
}

.box-results {
    border:1px solid $primary;
    position: relative;
    .media {
        position: relative;

        &::after {
            background-color: $primary;
            height: 100%;
            width: 100%;
            position: absolute;
            content: '';
            top: 0;
            opacity: 0.5;
        }

    }

    .title{
        padding-top:var(--space-2xl);
        padding-bottom:var(--space-l);
        padding-left: var(--space-l);
        padding-right: var(--space-l);
    }

    .txt{
        padding-top:var(--space-xl);
        padding-bottom:var(--space-xl);
        padding-left: var(--space-l);
        padding-right: var(--space-l);
    }

    &:hover {
        cursor: pointer;
        &::after{
            background-color: $primary;
            position: absolute;
            content: '';
            height: 100%;
            width: 65%;
            top: 0;
            z-index: -1;
        }
        a {
            color: #fff;

            .media {
                &::after {
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#013f4c+0,013f4c+68,013f4c+100&0.66+0,1+68,1+100 */
                    background: -moz-linear-gradient(top, rgba(1, 63, 76, 0.66) 0%, rgba(1, 63, 76, 1) 68%, rgba(1, 63, 76, 1) 100%);
                    /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(1, 63, 76, 0.66) 0%, rgba(1, 63, 76, 1) 68%, rgba(1, 63, 76, 1) 100%);
                    /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(1, 63, 76, 0.66) 0%, rgba(1, 63, 76, 1) 68%, rgba(1, 63, 76, 1) 100%);
                    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a8013f4c', endColorstr='#013f4c', GradientType=0);
                    /* IE6-9 */
                    opacity: 1;
                }

                .play {
                    span {
                        display: block;
                    }
                }
            }

        }
    }

    &.mini{
        padding-bottom: 0;
        border: none;
        &:hover{
            background: none;

            a{
                color:$primary
            }
        }
    }

    @include touch {
        padding-bottom: 2rem;

        .title{
            padding-top:var(--space-m);
            padding-bottom:0;
            padding-left: var(--space-xs);
            padding-right: var(--space-xs);
        }

        .txt{
            padding-top:0;
            padding-bottom:0;
            padding-left: var(--space-xs);
            padding-right: var(--space-xs);
            h2{
                font-size: var(--step-2);
            }
        }

        &:hover {
            &::after{
                width: 100%;
            }

            .txt{
                color: #fff;
            }
            a {
                .media {
                    .play {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }

}

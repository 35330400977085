.box-infermeria{
    > div, a{
        border:1px solid #fff;
        width: 310px;
        height: 310px;
        padding-top: calc(var(--space-xl) * 3);
    }
    &:hover{
       // cursor: pointer;
        .over{
            display: block !important;
        }
    }
    .over{
        display: none;
        position: absolute;
        top: -20px;
        height: 100vh;
        width: 370px;
        margin: 0 -15px;
        padding: var(--space-l);
        &.red{
            position: absolute;
            top: -60px;
            background-color: red;
            border: none;
            right: 0;
            padding: var(--space-xl);
        }
        .stroke{
            stroke: #fff;
        }
    }
    .is-pulled-right{
        float: right;
    }

    @include touch(){

        > div, a{
            width: 100%;
            height: auto;
            padding-top: var(--space-l);
            padding-bottom: var(--space-l);
        }

        .over{
            display: block;
            position: relative;
            top: -0;
            height: auto;
            width: 100%;
            margin: 0 ;
            padding: var(--space-l);
            &.red{
                display: none;
                position: relative;
                top: -0;
                background-color: red;
                border: none;
                right: 0;
                padding: var(--space-xl);
            }
            .stroke{
                stroke: #fff;
            }
        }

        .is-pulled-right{
            float: none;
        }
    }

    .stroke{
        stroke: $tertiary;
    }
}
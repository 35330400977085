.is-checkradio[type="checkbox"],
.is-checkradio[type="radio"] {
    // &:focus + label::before {
    //     @include focus;
    // }
    + label {
        &::before {
            top: .15em;
        }
    }
    
}

.is-checkradio[type="checkbox"] {
    // + label {
    //     &::before {
    //         border-radius: 0;
    //     }
    // }
    &:checked {
        + label {
            &::before {
                background-color: $black;
                border-color: $black;
            }
            &::after {
                border-color: $white;
                width: 0.45rem;
                height: .8rem;
                top: .4rem;
                left: .55rem;
              }
        }
    }
    
}

.is-checkradio[type="radio"] {
    + label {
        &::after {
            background: $black;
            top: .15em;
        }
    }
}
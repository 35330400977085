.cal {
    border-collapse: separate;
    border-spacing: 10px;
    @include mobile {
        margin-top: var(--space-l);
    }
    &__not-in-month {
        opacity: .8;
    }
    td {
        text-align: center;
    }
    tbody a {
        width: 3ch;
        height: 3ch;
        text-decoration: none;
        &:hover,
        &:focus {
            background-color: $black !important;
        }      
    }
    @include tablet {
        padding-left: var(--space-xl);
    }
}
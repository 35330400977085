/* Override Bulma icon margin styles */
.button .icon:first-child {
    margin: 0 0.5em 0 0 !important;
}

.button {
    text-transform: uppercase;
    height: auto;
    font-weight: bold;
    font-size: var(--step--1);
    text-decoration: none;
    border-radius: 10px;
    text-transform: none;
    border-color: $primary;
    // redefinim el color de l'outline, ja que per defecte és 'currentColor' i no es veu quan el color de text és blanc.
    &:focus,
    &:focus-visible {
        outline-color: $black !important;
    }

    @include mobile(){
        padding-left: 1rem;
        padding-right: 1rem;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
    &--mes {
        background-color: $primary!important;
        color: $white;
        &:hover{
            background-color: $black !important;
            border-color: $black !important;
        }
    }

    &--mapa {
        background-color: $white !important;
        color: $primary;
        border-color:$primary;
        border-radius: 0;
        padding-right: .85rem;
        padding-left:  .85rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        font-weight: 600;
        &:hover{
            background-color: $black !important;
            border-color: $black !important;
        }

        &.verd{
            background-color: $primary!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }
        &.blau{
            background-color: #9BD5E5!important;
            color: $primary;
            svg g {
                stroke: $primary;
            }
        }
        &.granate{
            background-color: #C92E54!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }
        &.fucsia{
            background-color: #8A098A!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }
        &.lila{
            background-color: #522EC9!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }
        &.vermell{
            background-color: #E04707!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }




        &.color1{
            background-color: #013f4c!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }
        &.color2{
            background-color: #C92E54!important;
            color: $white;
            svg g {
                stroke: $white;
            }
        }
        &.color3{
            background-color: #F7B05B!important;
            color: $primary;
            svg g {
                stroke: $primary;
            }
        }
        &.color4{
            background-color: #F8F991!important;
            color: $primary;
            svg g {
                stroke: $primary;
            }
        }
        &.color5{
            background-color: #9AE19D!important;
            color: $primary;
            svg g {
                stroke: $primary;
            }
        }
        &.color6{
            background-color: #D6E3F8!important;
            color: $primary;
            svg g {
                stroke: $primary;
            }
        }
    }

    &--tag{
        color:#2E6B78;
        border-color:#2E6B78;
        font-weight: 600;
        &:hover{
            color:#fff !important;
            background-color: $primary;
            border-color: $primary !important;
        }
        &.selected{
            color:#fff !important;
            background-color: $primary;
            border-color: $primary !important;
        }
    }
}
p.button {
    display: block;
    width: max-content;
}

.wrap-button-text {
    height: max-content;
    white-space: inherit;
}
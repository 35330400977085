.hv-40{
    max-height: 40vh;
    @include touch(){
        height: auto;
    }
}
.hv-50{
    max-height: 50vh;
    @include touch(){
        height: auto;
    }
}
.hv-60{
    max-height: 60vh;
    @include touch(){
        height: auto;
    }
}
.hv-70{
    max-height: 70vh;
    @include touch(){
        height: auto;
    }
}
.hv-80{
    max-height: 80vh;
    @include touch(){
        height: auto;
    }
}

.hv-90{
    max-height: 90vh;
    @include touch(){
        height: auto;
    }
}


.hv-100{
    overflow: hidden;
    max-height: 100vh;
    @include touch(){
        overflow:auto;
       // height: auto;
    }
}
.slider {
    .js & {
        &:not(.slick-initialized) {
            display: none;
        }
    }
    .slick-prev,
    .slick-next {
        color: currentColor;
        width: 26px;
        height: 41px;
        transform: scale(.6) translate(0, -30%);
        span {
            height: 100%;
            &::before {
                height: 100%;
                display: block;
                filter: invert(100%);
                content: '';
            }
        }
        &:focus {
            top: 50%;
            span {
                margin-left: 0;
            }
        }
    }
    .slick-prev-icon::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI1LjcxOSAzNi4wNTIgOS45MDYgMjAuNzE5IDI1LjcxOSA1LjM4NiAyMC45MDYuNzE5bC0yMC42MjUgMjAgMjAuNjI1IDIweiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    }
    .slick-next-icon::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibS4yODEgNS4zODYgMTUuODEzIDE1LjMzM0wuMjgxIDM2LjA1Mmw0LjgxMyA0LjY2NyAyMC42MjUtMjAtMjAuNjI1LTIweiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    }

    // slider destacats
    &--home {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        width:60%;
        .js & {
            // height: 100vh;
        }
        .slick-list {
            // per evitar l'espai esquerra i dreta
           // margin: 0 calc(var(--space-s) * -1);
            height: 100%;
            &::after{
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#013f4c+0,013f4c+68&1+0,0.8+68,0.4+99 */
                background: -moz-linear-gradient(left,  rgba(1,63,76,1) 0%, rgba(1,63,76,0.8) 68%, rgba(1,63,76,0.4) 99%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(1,63,76,1) 0%,rgba(1,63,76,0.8) 68%,rgba(1,63,76,0.4) 99%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(1,63,76,1) 0%,rgba(1,63,76,0.8) 68%,rgba(1,63,76,0.4) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#013f4c', endColorstr='#66013f4c',GradientType=1 ); /* IE6-9 */
                height: 100%;
                width: 100%;
                content: '';
                position: absolute;
                top: 0;
                }
        }
        .slick-track {
            display: flex;
            height: 100%;
        }
        .slick-slide {
            height: inherit;
            margin: 0;
            > div {
                height: 100%;
            }
        }
        a {
            position: relative;
            padding-bottom: calc(var(--space-2xl) * 2);
            text-decoration: none;
            &:hover {
                img {
                    opacity: 0.4;
                    transition: opacity .15s ease-in;
                }
            }
        }
        img {
            object-fit: cover;
            object-position: right;
            // height: 100vh;
        }

        .slick-autoplay-toggle-button{
            display:none;
        }

        @include mobile {
            width:100%;
            right: -0;
            .slick-list {
                // per evitar l'espai esquerra i dreta
               // margin: 0 calc(var(--space-s) * -1);
                height: 100%;
                &::after{
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#013f4c+0,013f4c+43,013f4c+72,013f4c+100&0.49+0,1+72,1+100 */
                    background: -moz-linear-gradient(top,  rgba(1,63,76,0.49) 0%, rgba(1,63,76,0.79) 43%, rgba(1,63,76,1) 52%, rgba(1,63,76,1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  rgba(1,63,76,0.49) 0%,rgba(1,63,76,0.79) 43%,rgba(1,63,76,1) 52%,rgba(1,63,76,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  rgba(1,63,76,0.49) 0%,rgba(1,63,76,0.79) 43%,rgba(1,63,76,1) 52%,rgba(1,63,76,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d013f4c', endColorstr='#013f4c',GradientType=0 ); /* IE6-9 */
                    height: 100vh;
                    width: 100%;
                    content: '';
                    position: absolute;
                    top: 0;
                    }
            }
            img {
                height: 60vh;
            }
        }

    }
}

.box-opcions{
    a{

        @include desktop{
            width: 100%;
        }

        @include widescreen{
            width: 100%;
        }

        @include fullhd{
            width: 80%;
        }

    }
    .over{
        display: none;
        line-height: 1.4rem;

        .txt{
            margin-left: 2rem;
            margin-right: 2rem;

            @include desktop{
                width: 100%;
            }
            @include widescreen{
                width: 80%;
            }

            @include fullhd{
                width: 70%;
            }
        }

        h3{
            white-space: nowrap;
         }

    }
    &:hover{
        cursor: pointer;
        h3{
            display: none
        }
        .over{
            display: block !important;
            &.video{
                cursor:auto
            }

        }
    }

}

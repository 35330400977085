// node_modules/bulma-scss/elements/_table.scss

// $table-color: $text-strong;
// $table-background-color: $scheme-main;

// $table-cell-border: 1px solid $border;
// $table-cell-border-width: 0 0 1px;
$table-cell-border-width: 0;
// $table-cell-padding: 0.5em 0.75em;
$table-cell-padding: var(--space-s);
// $table-cell-heading-color: $text-strong;

// $table-head-cell-border-width: 0 0 2px;
$table-head-cell-border-width: 1px;
// $table-head-cell-color: $text-strong;
$table-head-cell-color: $grey-dark;
// $table-foot-cell-border-width: 2px 0 0;
// $table-foot-cell-color: $text-strong;

// $table-head-background-color: transparent;
// $table-body-background-color: transparent;
// $table-foot-background-color: transparent;

// $table-row-hover-background-color: $scheme-main-bis;

// $table-row-active-background-color: $primary;
// $table-row-active-color: $primary-invert;

// $table-striped-row-even-background-color: $scheme-main-bis;
// $table-striped-row-even-hover-background-color: $scheme-main-ter;

// $table-colors: $colors;

.collapsible {
    .js & {
        .collapsible__title {
            font-size: var(--step-0);
            button {
                all: inherit;
                border: 0;
                width: 100%;
                border-bottom: 1px solid $grey-light;
                margin-top: 0;
                &:hover,
                &:active {
                    background-color: inherit;
                }
                &[aria-expanded="true"] {
                    border-bottom: 0;
                }
                &:focus {
                    @include focus;
                }
            }
            + div {
                margin-top: 0 !important;
                padding: 0 0 var(--space-m) 0;
                &:not(hidden) {
                    border-bottom: 1px solid $grey-light;
                }
            }
        }
    }
    button svg {
        display: initial;
    }
    [aria-expanded="true"] svg {
        transform: rotate(0.5turn);
        margin-right: 0;
        margin-left: var(--step-6);
    }
}

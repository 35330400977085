.home{
    background-color: $primary;
    position: relative;
    @include mobile(){
        background-color: #013f4c;

    }
    .btn{
        button, a {
            border-color: #fff;
            border-width: 1px;
            right: 0;
            &:hover{
                background-color: #fff;
                color:$primary;
            }
            &.search{
                position: absolute;
                top: 0;
                right: 13px;
                background-color: #fff;
                border-radius: 5px;
                padding: 12px;
                border-color: #fff;
                padding: 13px 12px 12px 12px;
            }
        }
    }

}

.z-index-1{
    z-index: 1;
}
.z-index-2{
    z-index: 2;
}
.z-index-3{
    z-index: 3;
}
.z-index-4{
    z-index: 4;
}
.z-index-5{
    z-index: 5;
}

.z-index--1{
    z-index: -1;
}
.z-index--2{
    z-index: -2;
}
.z-index--3{
    z-index: -3;
}
.z-index--4{
    z-index: -4;
}
.z-index--5{
    z-index: -5;
}
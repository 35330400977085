.mfp-bg {
    &.mfp-video {
        height: 100%;
        width: 100%;
        background-color: $primary;
    }
}

.mfp-video {
    top: 50%;
    left: 50%;
    height: 90%;
    width: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    button.mfp-close{
        width: 40px;
        height: 40px;
        line-height: auto;
        right: var(--space-m);
        top: var(--space-xs);
        padding: 0;
        &:active{
            top: var(--space-xs);
        }
    }
    .mfp-container {
        padding: 0;
        height: auto;
        .video {
            background-color: #fff;
            padding: 5rem 4rem 1rem 4rem;
            .bg {
                position: relative;
                &::after {
                    background-color: $primary;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    content: '';
                    top: 0;
                    opacity: 0.5;
                    content: '';
                }

                img {
                    height: 555px;
                    object-fit: cover;
                }
            }
        }
    }
    @include touch(){
        width: 90%;
        button.mfp-close{
            svg{
                filter:   brightness(0) invert(1);
            }
        }
        .mfp-container {
            .video {
                padding: 0rem;
                div + div{
                    padding:var(--space-m);
                }
            }
        }
    }
    @include mobile(){

        .mfp-container {
            .video {
                .bg{
                    img {
                        height: 255px;
                    }
                }
            }
        }
    }
}

.tab-menu {
    .js & {
        padding-left: 0;
        display: flex;
        list-style: none;
        li {
            padding: 0;
            // margin: 0 var(--space-s) 0 0;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            text-decoration: none;
            display: block;
            padding: var(--space-xs) var(--space-s);
            border-top: 2px solid $white;
            &:hover,
            &[aria-selected] {
                outline: 0;
                background-color: $grey-lighter;
                border-top-color: $primary;
            }
        }
    }
}

.tab-panel {
    .js & {
        margin-top: 0;
    }
    // Estils pels elements de text dins el panel
    h2 {
        font-size: var(--step-2);
    }
    // p,
    // ul,
    // ol {
    //     font-size: var(--step-9);
    // }
}

// Pestanyes en mòbil
@include mobile {
    .js .tab-menu {
        display: block;
        li {
            display: block;
            margin-right: 0;
            margin-top: 1px;
            &:first-child {
                margin-top: 0;
            }
        }
        // [aria-selected] {
        //     outline: 0;
        // }
    }
}
// Pestanyes verticals
.js .tabbed--vertical {
    @include tablet {
        display: flex;
        .tab-menu {
            display: block;
            flex: 1 0 25%;
            li {
                display: block;
                // margin: 0 0 var(--space-s) 0;
            }
            a {
                border-left: 2px solid $white;
                border-top: 0;
                display: block;
                &:hover,
                &[aria-selected] {
                    border-left-color: $primary;
                }
            }
        }
    }
}

.bg-img {
    object-fit: cover;
    opacity: .3;
    position: absolute;
    left: 0;
    top: 0;
    .has-background-secondary &,
    .has-background-grey-darker &,
    .has-background-grey & {
        filter: invert(100%);
    }
    .has-background-secondary & {
        opacity: .2;
    }
    .has-background-grey-darker & {
        opacity: 1;
    }
}

.bg-infermeria{
    position: absolute;
    right: 0;
    top: 0;
    filter: grayscale(1);
    &::before{
        position: absolute;
        content: '';
        height: 100vh;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+19,000000+100&1+19,0+100 */
        background: -moz-linear-gradient(left,  rgba(0,0,0,1) 19%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 19%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,0,0,1) 19%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        width: 100%;
        z-index: 1;
    }
    img{
        height: 100vh;
    }



    @include touch(){
        position: relative;
        &.bottom{
            margin-top: calc(var(--space-2xl) * -1) !important;
            &::before{
                position: absolute;
                content: '';
                height: 100%;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+18,000000+100&1+18,0+100 */
                background: -moz-linear-gradient(top,  rgba(0,0,0,1) 18%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 18%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(0,0,0,1) 18%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
                width: 100%;
                z-index: 1;
            }
        }
        img{
            height: 400px;
            object-fit: cover;
            width: 100%;
        }

    }

    @include mobile(){
        position: relative;
        img{
            height: 300px;
            object-fit: cover;
            width: 100%;

        }
    }
}

// Modificacions dels valors de les variables inicials (node_modules/bulma-scss/utilities/_initial-variables.scss)

// Colors
$black: #000;
$grey-darker: #fff;
$grey-lighter: rgb(242, 242, 242);
$grey-lightest: #f9f9f9;
// $yellow: #F5A623;
$green: #63AA16;
$red: #D0021B;
$white : #fff;

// Typography
$family-sans-serif: Helvetica, Arial, sans-serif;
$family-serif: Georgia, Times, 'Times New Roman', sans-serif;

// Spacing
$block-spacing: var(--space-m);

// Miscellaneous
$radius: 4px;

.pagination-list {
    > * + * {
        margin-top: 0 !important;
      }
    a {
      text-decoration: none;
    }
}
.pagination-previous,
.pagination-next {
  border: 0;
}

@include mobile {
  .pagination {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .pagination-previous {
    order: 1;
  }
  .pagination-list {
    order: 2;
    gap: var(--space-xs);
    li {
      flex-grow: 0;
    }
  }
  .pagination-next {
    order: 3;
  }
}
.help {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-top: var(--space-xs);
    &::before {
        content: '';
        display: block;
        margin-right: .5rem;
    }
    &.is-danger::before {
        height: 23px;
        width: 23px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIxLjAyOSAxLjk3MWE0LjE1IDQuMTUgMCAwIDAtMi45NDQtMS4yMiA0LjE0OSA0LjE0OSAwIDAgMC0yLjk0NCAxLjIyTDIuMzY4IDE0Ljc0NC4xMSAyMS40MDFhMS4xNjQgMS4xNjQgMCAwIDAgLjI4MSAxLjIwNWwuMDA0LjAwNGExLjE2MiAxLjE2MiAwIDAgMCAxLjIxNC4yNzhsLS4wMDguMDAzIDYuNjU3LTIuMjU4TDIxLjAzMSA3Ljg2YTQuMTUgNC4xNSAwIDAgMCAxLjIyLTIuOTQ0IDQuMTQ5IDQuMTQ5IDAgMCAwLTEuMjItMi45NDRsLS4wMDItLjAwMVpNNy40NDMgMTkuMzI1bC01LjcwMiAxLjkzNCAxLjkzNC01LjcwMiA5Ljc4NS05Ljc4NSAzLjc2NyAzLjc2Ny05Ljc4NCA5Ljc4NlpNMTkuOTY5IDYuNzk5bC0xLjY4IDEuNjgtMy43NjctMy43NjcgMS42OC0xLjY4YTIuNjY0IDIuNjY0IDAgMSAxIDMuNzY3IDMuNzY3WiIgZmlsbD0iI0QwMDIxQiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+")
    }
    &.is-success::before {
        height: 20px;
        width: 26px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuNTkzIDE2LjU0OSA5LjExMyAyMGwuMDA3LS4wMDcuMDA0LjAwNCAzLjUxLTMuNDQxLjc1NS0uNzQ4TDI2IDMuNDQ0IDIyLjQ4OCAwIDkuMTE4IDEzLjEwOCAzLjUxMiA3LjYxMiAwIDExLjA1NWw1LjU5OSA1LjQ5eiIgZmlsbD0iIzYzQUExNiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    }
}

// ------
// GLOBAL
// ------
@import "base/aileron-swap"; /*fitxer duplicat per afegir font-display: swap*/
@import "base/utopia";

// Bulma config
// ------------
@import "bulma-config/all";

// Variables
// ---------
@import "variables/hamburger";
//@import "variables/mfp";
@import "variables/slick-slider";
@import "variables/vars";

// Libraries
// ---------
@import "lib/lib";

// Mixins
// -------
@import "helpers/mixins";

// Base styles
// -----------
@import "base/base";

// Composition
// -----------
@import "composition/main-header";
@import "composition/columns";
//@import "composition/sidebar";

// Utilities
// ---------
@import "utilities/border";
@import "utilities/flow";
@import "utilities/full-link";
// @import "utilities/gradient";
@import "utilities/h-100";
@import "utilities/hv-100";
//@import "utilities/colors";
@import "utilities/hidden-svg";
@import "utilities/hyphens";
@import "utilities/is-relative";
@import "utilities/line-height-titols";
@import "utilities/link-decoration-none";
@import "utilities/radius";
@import "utilities/overflow";
@import "utilities/rounded";
@import "utilities/simple-list";
@import "utilities/static";
@import "utilities/table-responsive";
@import "utilities/text-columns";
@import "utilities/text-weight-black";
@import "utilities/tt-none";
@import "utilities/unflow";
@import "utilities/upp";
@import "utilities/w-100";
@import "utilities/wrapper";
@import "utilities/z-index";

// Blocks
// ------
@import "blocks/abc";
@import "blocks/advanced-search";
@import "blocks/b-img";
@import "blocks/bg-images";
@import "blocks/boxes-list";
@import "blocks/box-carrousel";
@import "blocks/box-imatge";
@import "blocks/box-infermeria";
@import "blocks/box-opcions";
@import "blocks/box-temes";
@import "blocks/box-results";
@import "blocks/box-testimonis";
@import "blocks/box-fragments";
@import "blocks/breadcrumbs";

@import "blocks/button";
@import "blocks/cal";
@import "blocks/checkradio";
@import "blocks/collapsible";
@import "blocks/cols-list";
//@import "blocks/download-doc";
@import "blocks/dropdown-menu";
@import "blocks/footer";
@import "blocks/form";
@import "blocks/form-help";
@import "blocks/form-label";
@import "blocks/full-bleed";
@import "blocks/gallery";
//@import "blocks/highlighted-link";
//@import "blocks/highlighted-p-1";
//@import "blocks/highlighted-p-2";
@import "blocks/icon";
@import "blocks/ihavecookies";
@import "blocks/image";
@import "blocks/home";
@import "blocks/home-box";
//@import "blocks/home-box-infermeria";
@import "blocks/hv";
@import "blocks/lang-menu";
@import "blocks/linia-temps";
//@import "blocks/main-header";
@import "blocks/main-nav";
@import "blocks/mapa";

//@import "blocks/mfp";
@import "blocks/modal";
@import "blocks/navigation";
@import "blocks/pagination";
@import "blocks/play";
// @import "blocks/prod-cientifica-list";
//@import "blocks/related-docs";
@import "blocks/results-list";
@import "blocks/scrolltop";
@import "blocks/search-form";
//@import "blocks/search-form2";
@import "blocks/share-list";
@import "blocks/site-logo";
@import "blocks/skip";
@import "blocks/slider";
@import "blocks/slider-center";
@import "blocks/social-list";
@import "blocks/tabs";
@import "blocks/tags";
@import "blocks/tesaure";
@import "blocks/teasure-nav";
@import "blocks/toggle";
@import "blocks/video";
@import "blocks/video-ample";
@import "blocks/video-container";

mark {
    background-color: $primary;
    color: #fff;
    border-radius: .5em;
    display: inline-block;
    padding: 0 .5em;
}


// només utilitzar pels exemples:
.box {
    color: #fff;
    height: 100%;
    background-color: #666;
    padding: var(--step-3);
}
.test::before {
    content: "";
    display: inline-block;
    width: 1em; /*Modificar segons la necessitat. Ha de ser igual que el height.*/
    height: 1em; /*Modificar segons la necessitat. Ha de ser igual que el width.*/
    background-image: url("/assets/img/sprites-svg/skull.svg");
    margin-right: var(--step-1);
}
.test--base64::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIEZvbnQgQXdlc29tZSBGcmVlIDUuMTUuNCBieSBAZm9udGF3ZXNvbWUgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbSBMaWNlbnNlIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20vbGljZW5zZS9mcmVlIChJY29uczogQ0MgQlkgNC4wLCBGb250czogU0lMIE9GTCAxLjEsIENvZGU6IE1JVCBMaWNlbnNlKSAtLT48cGF0aCBkPSJNMjU2IDBDMTE0LjYgMCAwIDEwMC4zIDAgMjI0YzAgNzAuMSAzNi45IDEzMi42IDk0LjUgMTczLjcgOS42IDYuOSAxNS4yIDE4LjEgMTMuNSAyOS45bC05LjQgNjYuMmMtMS40IDkuNiA2IDE4LjIgMTUuNyAxOC4ySDE5MnYtNTZjMC00LjQgMy42LTggOC04aDE2YzQuNCAwIDggMy42IDggOHY1Nmg2NHYtNTZjMC00LjQgMy42LTggOC04aDE2YzQuNCAwIDggMy42IDggOHY1Nmg3Ny43YzkuNyAwIDE3LjEtOC42IDE1LjctMTguMmwtOS40LTY2LjJjLTEuNy0xMS43IDMuOC0yMyAxMy41LTI5LjlDNDc1LjEgMzU2LjYgNTEyIDI5NC4xIDUxMiAyMjQgNTEyIDEwMC4zIDM5Ny40IDAgMjU2IDB6bS05NiAzMjBjLTM1LjMgMC02NC0yOC43LTY0LTY0czI4LjctNjQgNjQtNjQgNjQgMjguNyA2NCA2NC0yOC43IDY0LTY0IDY0em0xOTIgMGMtMzUuMyAwLTY0LTI4LjctNjQtNjRzMjguNy02NCA2NC02NCA2NCAyOC43IDY0IDY0LTI4LjcgNjQtNjQgNjR6Ii8+PC9zdmc+");
}
.test--color::before {
    background-image: url("/assets/img/sprites-svg/skull-fuchsia.svg");
}

.text {
    a {
        text-decoration: underline;
        font-weight: 700;
    }
}

@include tablet {
    .auto-column {
        font-size: 0.9em;
        column-count: 2;
    }
}

.loading {
    path {
        fill: #013F4C;

    }
}

.mfp-hide{
    display: none;
}

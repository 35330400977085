html {
    scroll-behavior: smooth;
    overflow-x: hidden; /* Necessari pel Safari */
}
body {
    line-height: 1.5;
    overflow-x: hidden;
    font-family: 'aileron', $family-sans-serif;
     font-size: var(--step-0);
    font-weight: 400;
   // font-size: 18px;
    color: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

h1 {
    font-size: var(--step-4);
    font-family: $family-secondary;
}

h2 {
    font-size: var(--step-3);
    font-family: $family-secondary;
}

h3 {
    font-size: var(--step-2);
}

h4 {
    font-size: var(--step-1);
}
h5 {
    font-size: var(--step-0);
    font-weight: normal;
    margin: 0;
}
h6 {
    font-size: var(--step--1);
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding-left: 0;
}

// Estils de llistes (https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
ul:not([role]):not([class*="cols-list-"]):not([class*="boxes-list-"]),
ol:not([role]) {
  padding-left: 0;
  margin-left: var(--space-s-l);
  > * + * {
    margin-top: var(--space-s);
  }
}

ul:not([role]):not([class*="cols-list-"]):not([class*="boxes-list-"]) {
  list-style-position: inside;
    // Ocultem el ::marker
  ::marker {
    content: "";
    font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
  }
  > li {
    padding-left: 1.25rem;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: .5em;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $primary;
    }
  }
  ul {
    margin-top: var(--space-s);
    margin-left: 0;
    font-size: var(--step--1);
    li {
        padding-left: 1rem;
        &::before {
            content: "";
            top: 0.5em;
            width: 5px;
            height: 5px;
            background-color: $primary;
        }
    }
    ul {
        font-size: var(--step--2);
        margin-left: var(--space-s);
        li {
            padding-left: .75rem;
            &::before {
                top: 0.7em;
                width: 4px;
                height: 1px;
                background-color: $grey-dark;
                border-radius: 50%;
            }
        }
        > * + * {
            margin-top: var(--space-xs);
          }
    }
    > * + * {
      margin-top: var(--space-s);
    }
  }
}

ol:not([role]) {
    list-style: decimal;
    list-style-position: inside;
    li {
        &::marker {
            font-weight: bold;
        }
    }
}

a {
    color: $link;
    transition: text-decoration-color 0.1s ease-in;
    word-break: break-word;
    text-decoration: none;
    &:hover {
        text-decoration-color: transparent;
    }
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

strong em,
em strong {
    font-weight: bold;
    font-style: italic;
}

hr {
    border: 0;
    border-top: 1px solid $grey-dark;
}

// address {
//   font-style: normal;
// }

abbr {
  text-decoration: none;
}

:focus {
  @include focus;
}

table:not(.cal):not(.activitats) {
    @extend .table;
    border-spacing: 10px;
    thead {
        th {
            font-family: $family-secondary;
            font-size: var(--step--3);
            border-color: $grey-light;
        }
    }
    tbody {
        th {
            color: $grey-dark;
        }
        tr:nth-child(odd) {
            th {
                background-color: $grey-lighter;
            }
            td {
                background-color: $grey-lightest;
            }
        }
    }
}

// figure {
//     img {
//         display: inline-block;
//     }
// }
figcaption {
    color: $white;
    background-color: $grey;
    font-size: var(--step--2);
    padding: var(--space-xs);
}

img {
    height: auto;
}

fieldset {
    border: 0;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}
legend {
    font-size: var(--step-2);
    font-weight: 600;
    padding: 0;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: var(--space-s);
    font-weight: bold;
}

iframe {
    border: 0;
}

dl {
    font-size: var(--step--1);
    div {
        display: flex;
        flex-direction: column;
        border-top: 2px solid $black;
        padding-top: var(--space-xs);
        @include tablet {
            flex-direction: row;
            gap: var(--space-s);
        }
        + div {
            border-top-width: 1px;
            margin-top: var(--space-xs);
        }
    }
    dt {
        font-weight: bold;
        @include tablet {
            flex: 0 0 10rem;
        }
    }
}

// summary {
//   letter-spacing: 1px;
//   padding: get-size("300") * 0.75 get-size("400");
//   position: relative;
//   &::marker {
//     content: "";
//   }
//   /*Safari: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details#customizing_the_disclosure_widget*/
//   &::-webkit-details-marker {
//     display: none;
//   }
//   &::after {
//     content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOS45OTggMTEuNzU0TDEwLjAwMSAxMS43NTYgMTIuNDMxIDkuMzI2IDEyLjk2MyA4Ljc4OSAxOS4xMzEgMi42MjEgMTYuNyAwLjE5IDEwLjAwMiA2Ljg4OCAzLjMwNCAwLjE5IDAuODczIDIuNjIxIDcuNTY5IDkuMzIyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDggLTIzMDQpIHRyYW5zbGF0ZSgxMTQ4IDIzMDQpIi8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
//     position: absolute;
//     right: get-size("400");
//     top: 50%;
//     transform: translateY(-50%);
//   }
//   + div {
//     padding: get-size("300") get-size("400") get-size("400");
//     > ul:not([role]) {
//       margin-left: 0;
//       > li {
//         padding-left: get-size("400");
//         &::before {
//           content: "";
//           width: get-size("300") * 0.5;
//           height: get-size("300") * 0.5;
//           background-color: get-color("primary");
//           top: 0.4em;
//         }
//       }
//     }
//   }
// }
// details {
//   border: 2px solid get-color("black");
//   &[open] {
//     summary::after {
//       content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOS45OTggMTEuNzU0TDEwLjAwMSAxMS43NTYgMTIuNDMxIDkuMzI2IDEyLjk2MyA4Ljc4OSAxOS4xMzEgMi42MjEgMTYuNyAwLjE5IDEwLjAwMiA2Ljg4OCAzLjMwNCAwLjE5IDAuODczIDIuNjIxIDcuNTY5IDkuMzIyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDggLTIzNzkpIHJvdGF0ZSgtMTgwIDU4NCAxMTk1LjUpIi8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
//     }
//     * {
//       font-size: get-size("300");
//     }
//   }
// }

// A11y toggle (http://edenspiekermann.github.io/a11y-toggle/)
[aria-hidden="true"],
[data-a11y-toggle]:not([aria-controls]) {
    display: none;
}

// Excepcions (https://github.com/edenspiekermann/a11y-toggle/commit/74d9d7ff3fe98dd78f8ae96f08595691ea52c329)
/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
// .gm-style [aria-hidden="true"],
.scrolltop [aria-hidden="true"],
// .slider [aria-hidden="true"],
.icon[aria-hidden="true"],
.hidden-svg[aria-hidden="true"],
.mfp-close [aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
}

.box-carrousel{

    .close{
        position: absolute;
        top: 50%;
        right: 10%;
        font-size: 2rem;
        transform: translate(-50%, -50%);
        @include mobile(){
            top: 12%;
            right: 2%;
        }
    }
}

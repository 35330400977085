.box-fragments {
    .media {
        position: relative;

        &::after {
            background-color: $primary;
            height: 100%;
            width: 100%;
            position: absolute;
            content: '';
            top: 0;
            opacity: 0.5;
            content: '';
        }
        .play{
            padding:10px 10px 10px 15px
        }
    }

    .txt{
        padding-top:var(--space-s);
        h2{
            font-size: var(--step-1);
        }

    }

    &:hover {
        .media {
            position: relative;
            &::after {
                opacity: 0.0;
            }

        }
    }


    @include touch {
        .txt{
            padding-top:var(--space-m);
        }

    }

}

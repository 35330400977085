#main-nav .has-submenu, #main-nav .menu-item-has-children {
    // submenú mòbil i tablet
    letter-spacing: normal;

    &.open {
       > a{
            &:after{
                content: '';
                display: block;
                position: absolute;
                bottom: -1.55rem;
                height: 0.688rem;
                width: 100%;
                background-color: bulmaRgba($black, 0.8);
                transition: all .2s ease-in-out;
            }
            @include touch{
                &:after{
                    display: none;
                }
            }
        }

        > ul {
          opacity: 1;
          display: flex;
          flex-wrap: wrap;

          > li {
            width: 20%;
            &:last-child::after {
              height: 1px;
            }
          }

        }
    }

    button {
        background: transparent;
        border: 0;
        padding: 0;
        vertical-align: middle;
        margin-right: -1rem;
        cursor: pointer;
        > span {
          padding: 0 10px;
          width: 12px;
          height: 12px;
          display: block;
          box-sizing: content-box;
          &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            //background-image: url(../img/sprites-svg/avall.svg);
            background-repeat: no-repeat;
          }
        }
        &[aria-expanded="true"] {
          span::after {
            transform: rotate(.5turn);
          }
        }
    }

    > ul {
        opacity: 0;
        min-width: 15rem;
        transition: all 0.5s ease;
        display: none;
        position:absolute;
        left:0;
        right:0;
        top:27px;
        background-color:  bulmaRgba($black, 0.8);
        padding: 0;
        margin-left: 0;
        width: 134%;
        &::before {
            @include triangle(0.5rem, map-get($custom-colors, darker), bottom);
            position: absolute;
            top: -1rem;
            left: 2.3125rem;
        }

        li{
            padding: 0 1.25rem;
            z-index:2;
            color: $white;

            &:first-child(){
                border: none;
                width: 100%;
                padding: 0;
                a {
                    border: none;
                    font-size: $size-normal * 1.25;
                }
            }
            &:last-child(){
                border-bottom: 1px solid transparent;
            }

            > a{
                color: $primary;
                display: block;
                text-decoration: none;
                font-size: $size-normal * 0.925;
                padding-bottom: 1rem;
                border-bottom: 1px solid $white;
                padding-bottom: 10px;
                margin-bottom: 1rem;
                font-weight: 700;
                &:hover{
                    color:$primary;
                }
                @include touch {
                    $color: $black;
                    font-size: $size-normal;
                    font-weight: 700;

                }
            }
            > p{
                min-height: 143px;
            }
            > ul{
                display: block !important;
                opacity: 1 !important;
                padding: 0 !important;
                min-height: 143px;
                li{
                    padding: 0 !important;
                    width: 100% !important;
                    &:first-child(){
                        a {
                            font-size: $size-normal * 0.875 !important;
                        }
                    }
                    &:last-child(){
                        border-bottom: 1px solid transparent;
                    }
                    a{
                        border:none;
                        margin:0;
                        font-size: $size-normal * 0.85 !important;
                        padding-bottom: 8px !important;
                    }
                }
            }

        }
    }

    // submenú tablet i moblie
    @include touch {
        ul{
            padding-left: 0 !important;
            border-top: 2px solid bulmaRgba($black, 0.2);
            padding-top: 1rem;
            position: relative;
            background-color:transparent ;
            width: auto;
            top: 12px;
            &::before{
                display: none;
            }
             li{
                padding: 0 1rem;
                a{
                    color: $black;
                    border:none;
                    padding: 0;
                    margin: 0;
                    font-weight: 300;
                }
                &:first-child(){
                    padding: 0 1rem;
                    a {
                        font-size: $size-normal !important;
                    }
                }

                ul {
                    display: none !important;
                }
            }
        }


    }

}

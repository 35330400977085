.box-imatge {
    position: absolute;
    //top: 108px;
    top: -60px;
    right: -1%;
    height: 100vh;
    width: 45%;

    > div {
        a {
            &::before {
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,013f4c+50,013f4c+82,013f4c+100&1+0,0.5+50,0.8+82,1+100 */
                background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(1, 63, 76, 0.5) 50%, rgba(1, 63, 76, 0.8) 82%, rgba(1, 63, 76, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(1, 63, 76, 0.5) 50%, rgba(1, 63, 76, 0.8) 82%, rgba(1, 63, 76, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(1, 63, 76, 0.5) 50%, rgba(1, 63, 76, 0.8) 82%, rgba(1, 63, 76, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#013f4c', GradientType=1);
                /* IE6-9 */
                height: 100vh;
                width: 100%;
                content: '';
                position: absolute;
                z-index: 1;
            }

            &:hover{
                &::before {
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,013f4c+50,013f4c+82,013f4c+100&1+0,0.5+50,0.8+82,1+100 */
                background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(1, 63, 76, 0.7) 50%, rgba(1, 63, 76, 0.9) 82%, rgba(1, 63, 76, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(1, 63, 76, 0.7) 50%, rgba(1, 63, 76, 0.9) 82%, rgba(1, 63, 76, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(1, 63, 76, 0.7) 50%, rgba(1, 63, 76, 0.9) 82%, rgba(1, 63, 76, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#013f4c', GradientType=1);
                }
            }

        }
    }

    img {
        position: absolute;
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }

    @include touch() {
        position: relative;
        top: -0;
        right: -0%;
        height: 40vh!important;
        width: 100%;

        >div {
            a {
                &::before {
                    height: 100%;
                    height: 40vh;
                    background: none;
                    background-color: $primary;
                    opacity: 0.8;
                }
            }
        }


        img {
            object-fit: cover;
            object-position: right;
            height: 40vh;
        }
    }

    .play{
        display: block;
        text-align: center;
        padding: 20px 5px 0 0px;
        height: 180px;
        width: 180px;
    }
}

.mapa{
    #map, iframe{
        width: 100%;
        height: 70vh;
        margin-bottom: -8px;

        @include touch(){
            height: 500px;
            margin-bottom: 0;
        }
    }
    /*.content{
        height: 100vh;
        overflow-y: auto;
    }*/
}

.mapa-view{
    #map, iframe{
        width: 100%;
        height: 40vh;
        margin-bottom: -8px;

        @include touch(){
            height: 500px;
            margin-bottom: 0;
        }
    }
    /*.content{
        height: 100vh;
        overflow-y: auto;
    }*/
}
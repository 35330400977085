.tesaure {
    background-color: $primary;
    position: relative;

    .main-content{
        overflow: hidden;
        margin-top: -8rem;
        padding-top: 8rem;
    }

    .btn {

        button,
        a {
            border-color: #fff;
            border-width: 1px;
            right: 0;

            &:hover {
                background-color: #fff;
                color: $primary;
                svg{
                    filter: none;
                    opacity: 1;
                }
            }

            &.search {
                position: absolute;
                top: 0;
                right: 13px;
                background-color: #fff;
                border-radius: 5px;
                padding: 12px;
                border-color: #fff;
                padding: 13px 12px 12px 12px;
            }
        }
    }


    .content {
        position: relative;
        top: 0;
        right: 0;
        color: #013f4c;
        margin-top: -12rem;


        &:before{
            background-color: #fff;
            height: 200%;
            position: absolute;
            top: -18rem;
            left: 0;
            width: 200%;
            content: "";
            z-index: -1;
        }

        @include touch(){
            margin-top: .5rem;
            padding-top: .5rem !important;
            &:before{
                height: 100%;
                width: 200%;
                top: 0;
                left: -10%;
            }
            padding: 0;
        }
    }


    .subthemes {

        //$base-size, $decrease-value, $left-value
        @include tesaure-list(2.5rem, 0.5, 1rem);

        @include touch(){
            padding-top: 1rem !important;
            @include tesaure-list(2rem, 0.35, 0.5rem);
            svg{
                width: 25px;
            }
        }



        a {
            text-decoration: none;
            color: #809fa5;
            display: block;
            &:hover{
                background-color: #fff !important;
                color: $primary !important;
                svg{
                    filter: none!important;
                    opacity: 1 !important;
                }
            }
            span{
                display: block;
                width: 80% !important;
            }
        }

        li {
            width: 100%;
            padding: 0 !important;
            margin: 0 !important;

            &::before {
                display: none !important;
            }
        }

        .has-submenu {

            li {
                padding: 0;
                padding-left: 0 !important;
                margin: 0 !important;


            }

            > a {
                padding-top: 0.8rem;
                padding-bottom: 0.8rem;
                color: #fff;
                background-color: rgba($color: #ffffff, $alpha: .1);

                svg {
                    filter: brightness(0) invert(1);
                    opacity: 0.5;
                }

            }

            +li {
                padding-top: 0.85rem !important;
            }

            ul {
                position: relative;
                display: none;
                padding-left: 0;
                margin-left: 0 !important;
                margin-top: 0 !important;
                padding-bottom: 0.75rem;

                &.open {
                    display: block;
                }

                a {

                    &.current {
                        color: $primary;
                        margin-right: -0.5rem;
                        margin-left: -0.5rem;
                        background-color: #fff;
                        -webkit-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
                        box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                        margin-top: 2px !important;
                        margin-bottom: 2px !important;
                        span{
                            padding-top: 5px !important;
                            padding-bottom: 5px !important;
                            }
                    }
                }

                li {

                    &.bg {
                        background-color: #fff;
                        .has-submenu{
                            +li {
                                padding-top: 0rem !important;
                            }

                        }
                        a {
                            color: $primary;
                            padding-top: 3px;
                            padding-bottom: 3px;

                            svg {
                                filter: none;
                                opacity: 1;
                                height: 35px;
                            }

                            &:hover{
                                background-color: $primary !important;
                                color: #fff !important;
                                svg{
                                    filter: brightness(0) invert(1) !important;
                                    opacity: 0.5 !important;
                                                }
                            }

                        }

                        >a {
                            padding-top: 1rem;
                        }

                        >ul {
                            box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, .2) !important;
                            padding-bottom: 0.5rem;

                            &::before {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }

            &.current {
                background-color: rgba($color: #ffffff, $alpha: .1);
            }
        }

    }
        iframe{
            min-height: 228px;
        }




}
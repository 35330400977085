.input,
.button {
    &.is-large {
        font-size: var(--step-1);
    }
}

// Arreglar focus (node_modules/bulma-scss/utilities/_controls.scss l. 34)
.button:active, .button:focus, .file-cta:active, .file-cta:focus, .file-name:active, .file-name:focus, .input:active, .input:focus, .is-active.button, .is-active.file-cta, .is-active.file-name, .is-active.input, .is-active.textarea, .is-focused.button, .is-focused.file-cta, .is-focused.file-name, .is-focused.input, .is-focused.textarea, .select select.is-active, .select select.is-focused, .select select:active, .select select:focus, .textarea:active, .textarea:focus {
    @include focus;
}

// Fa visible el focus a l'input[type="file"]
.file-label {
    border-radius: 0;
    &:focus-within:not(:focus-visible) {
        outline: 2px solid $black;
        outline-offset: 0.25rem;
    }
}

// Separació de checkboxes i radiobuttons
ul.field {
    > * + * {
        margin-top: var(--space-xs);
    }
}

// input file retocs
.file-cta {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBkPSJNMzY0LjIgODMuOGMtMjQuNC0yNC40MS02NC0yNC40MS04OC40IDBsLTE4NCAxODRjLTQyLjA5IDQyLjEtNDIuMDkgMTEwLjMgMCAxNTIuNCA0Mi4xIDQyLjEgMTEwLjMgNDIuMSAxNTIuNCAwbDE1Mi0xNTJjMTAuOS0xMC45IDI4LjctMTAuOSAzOS42IDAgMTAuOSAxMC45IDEwLjkgMjguNyAwIDM5LjZsLTE1MiAxNTJjLTY0IDY0LTE2Ny42IDY0LTIzMS42IDAtNjMuOTUtNjQtNjMuOTUtMTY3LjYgMC0yMzEuNmwxODQtMTg0YzQ2LjMtNDYuMjggMTIxLjMtNDYuMjggMTY3LjYgMHM0Ni4zIDEyMS4zIDAgMTY3LjZsLTE3NiAxNzZjLTI4LjYgMjguNi03NSAyOC42LTEwMy42IDAtMjguNjEtMjguNi0yOC42MS03NSAwLTEwMy42bDE0NC0xNDRjMTAuOS0xMC45IDI4LjctMTAuOSAzOS42IDAgMTAuOSAxMC45IDEwLjkgMjguNyAwIDM5LjZsLTE0NCAxNDRjLTYuNyA2LjctNi43IDE3LjcgMCAyNC40IDYuNyA2LjcgMTcuNyA2LjcgMjQuNCAwbDE3Ni0xNzZjMjQuNC0yNC40IDI0LjQtNjQgMC04OC40eiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 22px 20px;
    background-position: 15px 50%;
    padding-left: 50px;
  }
.file-name {
    background-color: $file-name-border-color;
}
.breadcrumbs {
    @include touch {
       // margin-top: var(--space-l);
    }
    @include desktop {
      //  margin-top: var(--space-xl);
    }
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--space-s);
      //  margin-top: var(--space-m);
        font-size: var(--step-0);
        flex-wrap: wrap;
        @include mobile {
            font-size: var(--step--1);
            line-height: 1;
        }
        @include tablet {
           // margin-top: 0;
            align-items: center;
            flex-grow: 1;

        }
        @include desktop {
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: var(--step-0);
        }
        @include fullhd {
          //  margin-top: var(--space-l);
            font-size: 1rem; /*perquè hi càpiga en una línia*/
            gap: var(--space-s);
        }
        li{
            position: relative;
            &::after{
                content:'';
                position: absolute;
                top: 11px;
                right: -10px;
                width: 2px;
                height: 2px;
                background-color: $primary;
                @include mobile {
                    top: 7px;
                }
            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
            a {
                text-decoration: none;
                color: $primary;

                &[aria-current="page"]
                {
                    //text-decoration: solid underline 1px;
                   // text-underline-offset: 15px;
                    font-weight: bold;
                }
            }

        }


    }

    &.blanc{
        li{
            &::after{
                background-color: #fff;
            }
            a{
                color: #fff;
            }

        }
    }

    &.infermeria{
        padding-top: var(--space-l);
        li{
            &::after{
                background-color: #fff;
            }
            a{
                color: #fff;
            }

        }

        @include touch(){
            background-color: #fff;
            padding-top: var(--space-xs);
            padding-bottom: var(--space-xs);
            background-color: #fff;
            li{
                &::after{
                    background-color: $primary;
                }
                a{
                    color:$primary;
                }
            }

        }

    }
}

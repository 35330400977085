.linia{
    background-color: $primary;
    position: relative;

    .main-content{
        overflow: hidden;
        margin-top: -8rem;
        padding-top: 8rem;
    }
    ul.temps{
        li{
            padding-left: var(--space-l) !important;
            padding-bottom: var(--space-3xl);
            margin-top: 0 !important;
            &:before{
                background-color: #fff !important;
                height: 15px !important;
                width: 15px !important;
                top: 1rem !important;
                border: 3px solid #ffffff;
                z-index: 99;
            }
            &:after{
                background-color: #fff;
                min-height: 130px;
                height: 100%;
                border:1px solid white;
                width: 3px;
                top: 20px;
                left: 6px;
                position: absolute;
                content: "";
            }

            &:last-child{
                &::after{
                    display: none;
                }
            }
            .button{
                border-color: #fff;
                padding: .5rem 1rem;
                line-height: normal;
                white-space: break-spaces;

                @include mobile(){
                    width: 70%;
                }

                &:hover{
                    background-color: #fff;
                    color: #013f4c;
                }
                &.current{
                    background-color: #fff;
                    color: #013f4c;
                }
            }

            &.selected{
                &::before{
                    background-color: #013f4c !important;
                }
                color:#fff;
                ul{
                    padding: 1rem 0.5rem .5rem;
                    border-radius: 10px;
                    background-color: #fff;
                    color: #013f4c;
                    margin-top: -1rem !important;
                    li{
                        padding-bottom: var(--space-2xs);
                        padding-left: var(--space-s) !important;
                        &:before{
                            display: none !important;
                        }
                        &:after{
                            display: none !important;
                        }
                        a{
                            color: #013f4c;
                        }

                        &.selected{
                            background-color: rgba($color: #013f4c, $alpha: .1);
                            padding: 0.75rem !important;
                            margin-left: .5rem !important;
                            border-radius: 10px;
                            margin-top: .5rem !important;
                            margin-bottom: .5rem !important;
                            ul{
                                padding: 0rem 0;
                                margin-top: 0.5rem !important;
                                margin-left: 0rem !important;
                                background-color: transparent;
                                li{
                                    padding: 0 !important;
                                    &:before{
                                        display: block !important;
                                        height: 8px !important;
                                        width: 8px !important;
                                        top: 0.85rem !important;
                                        left: .5rem !important;
                                        border:2px solid white;
                                        background-color: #013f4c !important;
                                    }
                                    a{
                                        padding: .2rem 1.5rem !important;

                                    }
                                    ul {
                                        padding-left: 1rem;
                                    }
                                    &.selected {
                                        padding: 0 0.75rem 0.75rem  0!important;
                                        border-radius: 5px !important;
                                        background-color: rgba($color: #013f4c, $alpha: .8);
                                        a {
                                            color: #fff;
                                        }
                                        svg g g g path, svg g g g line {
                                            stroke: #fff;
                                        }
                                        svg.mr-s g g {
                                            stroke: #fff;
                                        }
                                        svg path {
                                            stroke: #fff;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content {
        position: relative;
        top: 0;
        right: 0;
        color: #013f4c;
        margin-top: -8rem;


        &:before{
            background-color: #fff;
            height: 200%;
            position: absolute;
            top: -18rem;
            left: 0;
            width: 200%;
            content: "";
            z-index: -1;
        }

        @include touch(){
            margin-top: -0;
            &:before{
                height: 100%;
                width: 200%;
                top: 0;
                left: -10%;
            }
            padding: 0;
        }
    }
}
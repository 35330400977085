.play{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border: 1px solid #fff;
    padding: 15px 20px 12px 25px;
    border-radius: 5px;
    display: flex;
    transition: display 0.5s;
    z-index: 1;
    svg{
        position: relative;

    }
    span{
        font-weight: 700;
        color: #fff;
        margin-left: 10px;
        margin-top: -1px;
        display: none;
    }
}

// Modificacions dels valors de les variables derivades (node_modules/bulma-scss/utilities/_derived-variables.scss)

// Colors
// ------
// $primary: $turquoise !default;
//$primary: #F5333F;
$primary: #013F4C;
// $info: $cyan !default;
// $success: $green !default;
// $warning: $yellow !default;
// $danger: $red !default;

// $light: $white-ter !default;
$light: #F3F6F5;
// $dark: $grey-darker !default;

// Invert colors

// $orange-invert: findColorInvert($orange) !default;
// $yellow-invert: findColorInvert($yellow) !default;
// $green-invert: findColorInvert($green) !default;
// $turquoise-invert: findColorInvert($turquoise) !default;
// $cyan-invert: findColorInvert($cyan) !default;
// $blue-invert: findColorInvert($blue) !default;
// $purple-invert: findColorInvert($purple) !default;
// $red-invert: findColorInvert($red) !default;

// $primary-invert: findColorInvert($primary) !default;
//$primary-light: findLightColor($primary) !default;
$primary-light: #013f4c6e  !default;
// $primary-dark: findDarkColor($primary) !default;
// $info-invert: findColorInvert($info) !default;
// $info-light: findLightColor($info) !default;
// $info-dark: findDarkColor($info) !default;
// $success-invert: findColorInvert($success) !default;
// $success-light: findLightColor($success) !default;
// $success-dark: findDarkColor($success) !default;
// $warning-invert: findColorInvert($warning) !default;
// $warning-light: findLightColor($warning) !default;
// $warning-dark: findDarkColor($warning) !default;
// $danger-invert: findColorInvert($danger) !default;
// $danger-light: findLightColor($danger) !default;
// $danger-dark: findDarkColor($danger) !default;
// $light-invert: findColorInvert($light) !default;
// $dark-invert: findColorInvert($dark) !default;

// General colors

// $scheme-main: $white !default;
// $scheme-main-bis: $white-bis !default;
// $scheme-main-ter: $white-ter !default;
// $scheme-invert: $black !default;
// $scheme-invert-bis: $black-bis !default;
// $scheme-invert-ter: $black-ter !default;

// $background: $white-ter !default;

// $border: $grey-lighter !default;
// $border-hover: $grey-light !default;
// $border-light: $grey-lightest !default;
// $border-light-hover: $grey-light !default;

// Text colors

// $text: $grey-dark !default;
// $text-invert: findColorInvert($text) !default;
// $text-light: $grey !default;
// $text-strong: $grey-darker !default;

// Code colors

// $code: darken($red, 15%) !default;
// $code-background: $background !default;

// $pre: $text !default;
// $pre-background: $background !default;

// Link colors

// $link: $blue !default;
$link: #013F4C;
// $link-invert: findColorInvert($link) !default;
// $link-light: findLightColor($link) !default;
// $link-dark: findDarkColor($link) !default;
// $link-visited: $purple !default;

// $link-hover: $grey-darker !default;
// $link-hover-border: $grey-light !default;

// $link-focus: $grey-darker !default;
// $link-focus-border: $blue !default;

// $link-active: $grey-darker !default;
// $link-active-border: $grey-dark !default;

// Custom colors
$secondary: #E7E0D6;
 $tertiary: #C92E54;
$custom-colors: (
    "secondary": $secondary,
     "tertiary": $tertiary
);

$radius:5px;

// Typography
$family-primary: 'aileron', $family-sans-serif;
// $family-secondary: Ultra, $family-serif;

// Lists and maps
$sizes: var(--step-8) var(--step-7) var(--step-6) var(--step-5) var(--step-4) var(--step-3) var(--step-2) var(--step-1) var(--step-0) var(--step--1) var(--step--2);

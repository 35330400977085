.hv-100 {
    height: 100vh;
}
.hv-90 {
    height: 90vh;
    overflow: hidden;
}
.hv-80 {
    height: 80vh;
}
.hv-70 {
    height: 70vh;
}
.hv-60 {
    height: 60vh;
}

.hv-50 {
    height: 50vh;
}

.hv-40 {
    height: 40vh;
}

.hv-30 {
    height: 30vh;
}

.hv-20 {
    height: 20vh;
}

.hv-10 {
    height: 10vh;
}

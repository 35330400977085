.slider-center {

    .slick-slide{
        opacity: 0.3;
        position: relative;
        margin-bottom: -8px;
        a{
            display: none;
        }
        &.slick-current{
            opacity: 1;
            img{
                outline: solid 4px white;
                outline-offset: -4px;
               /* position: absolute;
                top: 0;
                z-index: 1;*/
            }
            a{
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 2;
                top: 0;
                p{
                    position: absolute;
                    bottom: 1.8rem;
                }
            }
        }
    }

    .js & {
        &:not(.slick-initialized) {
            display: none;
        }
    }
    .slick-prev,
    .slick-next {
        color: currentColor;
        width: 26px;
        height: 41px;
        transform: scale(.8) translate(0, -50%);
        z-index: 1;
        span {
            height: 100%;
            &::before {
                height: 100%;
                display: block;
                filter: invert(100%);
                content: '';
            }
        }
        &:focus {
            top: 50%;
            span {
                margin-left: 0;
            }
        }
    }

    .slick-prev {
        left: 35%;
    }

    .slick-next {
        right: 35%;
    }

    .slick-prev-icon::before {
        filter: none !important;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI1LjcxOSAzNi4wNTIgOS45MDYgMjAuNzE5IDI1LjcxOSA1LjM4NiAyMC45MDYuNzE5bC0yMC42MjUgMjAgMjAuNjI1IDIweiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    }
    .slick-next-icon::before {
        filter: none !important;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibS4yODEgNS4zODYgMTUuODEzIDE1LjMzM0wuMjgxIDM2LjA1Mmw0LjgxMyA0LjY2NyAyMC42MjUtMjAtMjAuNjI1LTIweiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    }


}

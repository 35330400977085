.home-box {
    margin-left: calc(var(--space-s) * -1);
    .txt {
        a {
            color: #fff;
            margin-left: calc(var(--space-s) * -1);
            position: relative;

            &:hover {
                background-color: #fff;
                color: $primary;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                .stroke{
                    stroke: $primary;
                }
            }
        }
    }

    ul{
        margin-left: calc(var(--space-s) * -1);
        li{
            a{
                &:hover{
                    text-decoration: underline;
                }

            }
        }
    }

    @include mobile {
        margin-right: calc(var(--space-s) * -1);
        margin-left: calc(var(--space-s) * -1);
        ul {
            padding-right: 2rem;
            position: relative;

            &::after {
                border-right: 1px solid transparent;
                -o-border-image: linear-gradient(to top, #fff 0, rgba(1, 63, 76, 0) 100%);
                border-image: linear-gradient(to top, #fff 0, rgba(1, 63, 76, 0) 100%);
                border-image-slice: 1;
                position: absolute;
                content: "";
                width: 1px;
                height: 90%;
                top: 5%;
                left: 85%;
            }

            li {
                a {
                    font-size: 14px !important;
                }
            }
        }

        p {
            font-size: 16px !important;
        }
    }

}

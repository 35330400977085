.tesaure-nav {
    display: flex;
    position: relative;

    @include mobile(){
        flex-wrap: wrap;
    }

    a {
        text-decoration: none;
        text-wrap: nowrap;
        color: #809fa5;

        &:hover,
        &[aria-current="page"] {
            //text-decoration: solid underline 1px;
            // text-underline-offset: 15px;
           // font-weight: bold;
        }


        @include desktop(){
            svg{
                width: 30px;
            }
        }
        @include widescreen(){
            svg{
                width: auto;
                height: auto;
            }
        }

    }

    >li {
        width: auto !important;
        padding-left: 0 !important;

        @include mobile(){
            flex-grow: 1;
            svg{
                width: 20px;
            }
        }

        >a {
            border-top: 2px solid #809fa5;
            border-bottom: 2px solid #809fa5;
            border-left: 1px solid #809fa5;
            border-right: 1px solid #809fa5;
            display: block;
            padding: 0.5rem 1.2rem 0.5rem 1rem;
           // font-size: var(--step-3);
            font-weight: 700;

        }

        >&:first-child {
            >a {
                border-left: 2px solid #809fa5;
            }
        }

        >&:last-child {
            >a {
                border-right: 2px solid #809fa5;
            }
        }

        &.current {
            flex-grow: 1;
            @include mobile(){
                width: 100% !important;
                svg{
                    width: 25px;
                }
            }
            >a {
                border: 2px solid #fff;
                color: $primary;
                background-color: #fff;

                svg g g{
                    fill: #043039;
                }
                svg.qui-icon {
                    g g {
                        fill: none;
                    }
                    g path{
                        fill: #043039;
                    }
                    g g path{
                        stroke: #043039;
                        fill: none;
                    }
                    .object {
                        stroke: #043039;
                        fill: none;
                    }

                }
            }

        }
    }

    li {
        width: 100%;

        &::before {
            display: none !important;
        }
    }
}

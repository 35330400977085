.flow {
    > * + * {
        margin-top: var(--space-s);
    }

    // variants d'espai
    &--1 > * + * {
        margin-top: var(--space-xs);
    }
    &--3 > * + * {
        margin-top: var(--space-m);
    }
    &--4 > * + * {
        margin-top: var(--space-l);
    }
    &--5 > * + * {
        margin-top: var(--space-xl);
    }
    &--6 > * + * {
        margin-top: var(--space-2xl);
    }
    &--7 > * + * {
        margin-top: var(--space-3xl);
    }
}

// .flow {
//     --flow-space: var(--space-s);
//     > * + * {
//         margin-top: var(--flow-space);
//     }

//     // variants d'espai
//     &--1 > * + * {
//         --flow-space: var(--space-xs);
//     }
//     &--3 > * + * {
//         --flow-space: var(--space-m);
//     }
//     &--4 > * + * {
//         --flow-space: var(--space-l);
//     }
//     &--5 > * + * {
//         --flow-space: var(--space-xl);
//     }
//     &--6 > * + * {
//         --flow-space: var(--space-2xl);
//     }
//     &--7 > * + * {
//         --flow-space: var(--space-3xl);
//     }
// }

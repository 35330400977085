.tags{
    li{
        margin-bottom: 10px;
        margin-right: 10px;
        a{
            padding: 0.5rem 0.8rem;
            border:1px solid $primary;
            display: block;
            color:#013F4C;
            font-weight: 700;
            font-size: 1rem;

            &:hover{
                background-color: $primary;
                color: #fff;
            }
            &.current{
                background-color: $primary;
                color: #fff;

            }
            @include mobile(){
                font-size: .75rem;
                padding: 0.25rem .5rem;
                font-weight: 400;

            }
        }
    }
    &.mapa{
        li{
            margin-bottom: 1.2rem;
            a{
                display: inline;
            }
        }
    }
}

.social-list {
    gap: var(--space-s);
    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        header & {
            width: 2.1875rem;
            height: 2.1875rem;
        }
        footer & {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
    
}

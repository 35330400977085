.full-link {
    position: relative;
    // transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    a::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    // &:hover {        
    //     box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    // }
}

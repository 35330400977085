.main-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-s);
    margin-top: var(--space-m);
    @include tablet {
        margin-top: 0;
        align-items: center;
        flex-grow: 1;
    }
    @include desktop {
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    @include fullhd {
        margin-top: var(--space-l);
        font-size: 1rem; /*perquè hi càpiga en una línia*/
        gap: var(--space-s);
    }
    a {
        text-decoration: none;
        color: $primary;
        &:hover,
        &[aria-current="page"]
        {
            //text-decoration: solid underline 1px;
           // text-underline-offset: 15px;
            font-weight: bold;
        }
    }
}
